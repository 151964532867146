window.google = window.google || {};

google.homepageevents = {
  constants: {
    boxClickClass: '.tag-manager-box-link',
    boxClickEvent: 'boxCTAClick',
  },

  setup: function () {
    try {
      google.homepageevents.addOnClickEventToBoxLink();
    } catch (error) {}
  },

  addOnClickEventToBoxLink: function () {
    try {
      document.querySelectorAll(google.homepageevents.constants.boxClickClass).forEach((item) => {
        item.addEventListener('click', () => {
          google.homepageevents.addBoxLinkToDataLayer(item);
        });
      });
    } catch (error) {}
  },

  addBoxLinkToDataLayer: function (link) {
    try {
      var values = google.homepageevents.getBoxLinkValues(link);
      google.tagmanager.add(values);
    } catch (error) {}
  },

  getBoxLinkValues: function (link) {
    try {
      var h3Value = link.querySelector('h3').innerText.trim();
      var values = {
        boxCTA: h3Value,
        boxCTAURL: link.getAttribute('href'),
        event: google.homepageevents.constants.boxClickEvent,
      };
      return values;
    } catch (error) {}
  },

  init: function () {
    try {
      google.homepageevents.setup();
    } catch (error) {}
  },
};

if (document.readyState === 'complete' || document.readyState === 'loaded') {
  google.homepageevents.init();
} else {
  window.addEventListener('DOMContentLoaded', () => {
    google.homepageevents.init();
  });
}
